import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentTextArea.figmaUrl.ios} codeUrl={checklists.componentTextArea.codeUrl.ios} checklists={checklists.componentTextArea.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Textarea or in iOS we called it Text View is element that displays text to the user`}</p>
    <p>{`Use Legion Textview provide user-editable text, with support lots type and attribute to customize.`}</p>
    <h3>{`Usage Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`InlineTxtView(text: .constant("hello"))
OutlineTxtView(text: .constant("hello"))
`}</code></pre>
    <h3>{`Usage Theme`}</h3>
    <p>{`ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNInlineTextView(text: .constant("hello"))
LGNOutlineTextView(text: .constant("hello"))
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <p>{`Default LegionUI`}</p>
    <h3><strong parentName="h3">{` Outline `}</strong></h3>
    <div {...{
      "className": "outline",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/outlineios.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`This code sample demonstrates how to modify the type of the textfield :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`OutlineTxtView(
  titleKey: "Placeholder",
  text: .constant("Hello"),
  label: "Title",
  fontFamily: "",
  defaultBorderColor: Color.LGNTheme.tertiary300,
  errorBorderColor: Color.LGNTheme.error500,
  successBorderColor: Color.LGNTheme.success500,
  focussedBorderColor: Color.LGNTheme.information500,
  textFontColor: .black
)
.state(.error) // success, error, disable, idle, focused
.showCaption(caption: LGNContentModel(image: Image(systemName: "heart"), text: "caption text"), showCaption: true)
`}</code></pre>
    <h3><strong parentName="h3">{` Inline `}</strong></h3>
    <div {...{
      "className": "outline",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/inlineios.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`This code sample demonstrates how to modify the type of the textfield :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`InlineTxtView(
  titleKey: "Placeholder",
  text: .constant("Hello"),
  label: "Title",
  fontFamily: "",
  defaultSeparatorColor: Color.LGNTheme.tertiary300,
  errorBorderColor: Color.LGNTheme.error500,
  successBorderColor: Color.LGNTheme.success500,
  focussedBorderColor: Color.LGNTheme.information500,
  textFontColor: .black
)
.state(.success) // success, error, disable, idle, focused
.showCaption(caption: LGNContentModel(image: Image(systemName: "heart"), text: "caption text"), showCaption: true)
`}</code></pre>
    <div className="divi" />
    <h2>{`Attribute`}</h2>
    <p>{`ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <h3><strong parentName="h3">{` ThemeAGR `}</strong></h3>
    <undefined><div {...{
        "style": {
          "backgroundColor": "white",
          "width": "100%",
          "display": "grid",
          "gridTemplateColumns": "50% 50%"
        }
      }}>{`
  `}<div parentName="div" {...{
          "className": "item-three"
        }}>{`
    `}<div parentName="div" {...{
            "style": {
              "textAlign": "left"
            }
          }}>{`Outline`}</div>{`
    `}<div parentName="div" {...{
            "style": {
              "padding": "7px 0"
            }
          }}>{`
      `}<img parentName="div" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/outlineagr.png"
            }}></img>{`
    `}</div>{`
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        LGNOutlineTextView( titleKey: "Placeholder", text: .constant("Hello"), label: "Title", fontFamily: "",
        defaultBorderColor: Color.LGNTheme.tertiary300, errorBorderColor: Color.LGNTheme.error500, successBorderColor:
        Color.LGNTheme.success500, focussedBorderColor: Color.LGNTheme.information500, textFontColor: .black )
        .state(.error) // success, error, disable, idle, focused .showCaption(caption: LGNContentModel(image:
        Image(systemName: "heart"), text: "caption text"), showCaption: true)
      `}</code>{`
    `}</pre>{`
  `}</div>{`
  `}<div parentName="div" {...{
          "className": "item-three"
        }}>{`
    `}<div parentName="div" {...{
            "style": {
              "textAlign": "left"
            }
          }}>{`Inline`}</div>{`
    `}<div parentName="div" {...{
            "style": {
              "padding": "7px 0"
            }
          }}>{`
      `}<img parentName="div" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/inlineagr.png"
            }}></img>{`
    `}</div>{`
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        LGNInlineTextView( titleKey: "Placeholder", text: .constant("Hello"), label: "Title", fontFamily: "",
        defaultSeparatorColor: Color.LGNTheme.tertiary300, errorBorderColor: Color.LGNTheme.error500,
        successBorderColor: Color.LGNTheme.success500, focussedBorderColor: Color.LGNTheme.information500,
        textFontColor: .black ) .state(.success) // success, error, disable, idle, focused .showCaption(caption:
        LGNContentModel(image: Image(systemName: "heart"), text: "caption text"), showCaption: true)
      `}</code>{`
    `}</pre>{`
  `}</div>
      </div>
      <div {...{
        "className": "divi"
      }}></div></undefined>
    <h3><strong parentName="h3">{` ThemeMyTEnS `}</strong></h3>
    <undefined><div {...{
        "style": {
          "backgroundColor": "white",
          "width": "100%",
          "display": "grid",
          "gridTemplateColumns": "50% 50%"
        }
      }}>{`
  `}<div parentName="div" {...{
          "className": "item-three"
        }}>{`
    `}<div parentName="div" {...{
            "style": {
              "textAlign": "left"
            }
          }}>{`Outline`}</div>{`
    `}<div parentName="div" {...{
            "style": {
              "padding": "7px 0"
            }
          }}>{`
      `}<img parentName="div" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/outlinetens.png"
            }}></img>{`
    `}</div>{`
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        LGNOutlineTextView( titleKey: "Placeholder", text: .constant("Hello"), label: "Title", fontFamily: "",
        defaultBorderColor: Color.LGNTheme.tertiary300, errorBorderColor: Color.LGNTheme.error500, successBorderColor:
        Color.LGNTheme.success500, focussedBorderColor: Color.LGNTheme.information500, textFontColor: .black )
        .state(.error) // success, error, disable, idle, focused .showCaption(caption: LGNContentModel(image:
        Image(systemName: "heart"), text: "caption text"), showCaption: true)
      `}</code>{`
    `}</pre>{`
  `}</div>{`
  `}<div parentName="div" {...{
          "className": "item-three"
        }}>{`
    `}<div parentName="div" {...{
            "style": {
              "textAlign": "left"
            }
          }}>{`Inline`}</div>{`
    `}<div parentName="div" {...{
            "style": {
              "padding": "7px 0"
            }
          }}>{`
      `}<img parentName="div" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/inlinetens.png"
            }}></img>{`
    `}</div>{`
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        LGNInlineTextView( titleKey: "Placeholder", text: .constant("Hello"), label: "Title", fontFamily: "",
        defaultSeparatorColor: Color.LGNTheme.tertiary300, errorBorderColor: Color.LGNTheme.error500,
        successBorderColor: Color.LGNTheme.success500, focussedBorderColor: Color.LGNTheme.information500,
        textFontColor: .black ) .state(.success) // success, error, disable, idle, focused .showCaption(caption:
        LGNContentModel(image: Image(systemName: "heart"), text: "caption text"), showCaption: true)
      `}</code>{`
    `}</pre>{`
  `}</div>
      </div>
      <div {...{
        "className": "divi"
      }}></div></undefined>
    <h3><strong parentName="h3">{` ThemeIHS `}</strong></h3>
    <undefined><div {...{
        "style": {
          "backgroundColor": "white",
          "width": "100%",
          "display": "grid",
          "gridTemplateColumns": "50% 50%"
        }
      }}>{`
  `}<div parentName="div" {...{
          "className": "item-three"
        }}>{`
    `}<div parentName="div" {...{
            "style": {
              "textAlign": "left"
            }
          }}>{`Outline`}</div>{`
    `}<div parentName="div" {...{
            "style": {
              "padding": "7px 0"
            }
          }}>{`
      `}<img parentName="div" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/outlineihs.png"
            }}></img>{`
    `}</div>{`
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        LGNOutlineTextView( titleKey: "Placeholder", text: .constant("Hello"), label: "Title", fontFamily: "",
        defaultBorderColor: Color.LGNTheme.tertiary300, errorBorderColor: Color.LGNTheme.error500, successBorderColor:
        Color.LGNTheme.success500, focussedBorderColor: Color.LGNTheme.information500, textFontColor: .black )
        .state(.error) // success, error, disable, idle, focused .showCaption(caption: LGNContentModel(image:
        Image(systemName: "heart"), text: "caption text"), showCaption: true)
      `}</code>{`
    `}</pre>{`
  `}</div>{`
  `}<div parentName="div" {...{
          "className": "item-three"
        }}>{`
    `}<div parentName="div" {...{
            "style": {
              "textAlign": "left"
            }
          }}>{`Inline`}</div>{`
    `}<div parentName="div" {...{
            "style": {
              "padding": "7px 0"
            }
          }}>{`
      `}<img parentName="div" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/inlineihs.png"
            }}></img>{`
    `}</div>{`
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        LGNInlineTextView( titleKey: "Placeholder", text: .constant("Hello"), label: "Title", fontFamily: "",
        defaultSeparatorColor: Color.LGNTheme.tertiary300, errorBorderColor: Color.LGNTheme.error500,
        successBorderColor: Color.LGNTheme.success500, focussedBorderColor: Color.LGNTheme.information500,
        textFontColor: .black ) .state(.success) // success, error, disable, idle, focused .showCaption(caption:
        LGNContentModel(image: Image(systemName: "heart"), text: "caption text"), showCaption: true)
      `}</code>{`
    `}</pre>{`
  `}</div>
      </div>
      <div {...{
        "className": "divi"
      }}></div></undefined>
    <h3><strong parentName="h3">{` ThemeEazy `}</strong></h3>
    <undefined><div {...{
        "style": {
          "backgroundColor": "white",
          "width": "100%",
          "display": "grid",
          "gridTemplateColumns": "50% 50%"
        }
      }}>{`
  `}<div parentName="div" {...{
          "className": "item-three"
        }}>{`
    `}<div parentName="div" {...{
            "style": {
              "textAlign": "left"
            }
          }}>{`Outline`}</div>{`
    `}<div parentName="div" {...{
            "style": {
              "padding": "7px 0"
            }
          }}>{`
      `}<img parentName="div" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/outlineihs.png"
            }}></img>{`
    `}</div>{`
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        LGNOutlineTextView( titleKey: "Placeholder", text: .constant("Hello"), label: "Title", fontFamily: "",
        defaultBorderColor: Color.LGNTheme.tertiary300, errorBorderColor: Color.LGNTheme.error500, successBorderColor:
        Color.LGNTheme.success500, focussedBorderColor: Color.LGNTheme.information500, textFontColor: .black )
        .state(.error) // success, error, disable, idle, focused .showCaption(caption: LGNContentModel(image:
        Image(systemName: "heart"), text: "caption text"), showCaption: true)
      `}</code>{`
    `}</pre>{`
  `}</div>{`
  `}<div parentName="div" {...{
          "className": "item-three"
        }}>{`
    `}<div parentName="div" {...{
            "style": {
              "textAlign": "left"
            }
          }}>{`Inline`}</div>{`
    `}<div parentName="div" {...{
            "style": {
              "padding": "7px 0"
            }
          }}>{`
      `}<img parentName="div" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/inlineihs.png"
            }}></img>{`
    `}</div>{`
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        LGNInlineTextView( titleKey: "Placeholder", text: .constant("Hello"), label: "Title", fontFamily: "",
        defaultSeparatorColor: Color.LGNTheme.tertiary300, errorBorderColor: Color.LGNTheme.error500,
        successBorderColor: Color.LGNTheme.success500, focussedBorderColor: Color.LGNTheme.information500,
        textFontColor: .black ) .state(.success) // success, error, disable, idle, focused .showCaption(caption:
        LGNContentModel(image: Image(systemName: "heart"), text: "caption text"), showCaption: true)
      `}</code>{`
    `}</pre>{`
  `}</div>
      </div>
      <div {...{
        "className": "divi"
      }}></div></undefined>
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`titleKey`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The key for the localized title of the text field, describing its purpose, default value is empty string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`empty string `}<inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The text to display and edit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`not have`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label to represent the title of the text field, default value is nil`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nil (literal null value for objective-C classes)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leftIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The icon is on the left of the text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nil (literal null value for objective-C classes)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`prefix`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Will show prefix content with position left side in text field`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nil (literal null value for objective-C classes)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sufix`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Will show prefix content with position right side in text field`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nil (literal null value for objective-C classes)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`defaultBorderColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color will appear on the border of the text field, default value is Color tertiary300`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`color.tertiary300`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`errorBorderColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color will appear on the border of the text field, default value is Color tertiary300`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`color.error500`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`successBorderColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color will appear on the border of the text field on the success state, default value is Color success500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`color.error500`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      